import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t, i18n } = useTranslation()

  const getLang = () => {
    const obj = {};
    const params = window.location.search.replace("?", '')
    console.log(params);
    obj[params.split('=')?.[0]] = params.split('=')?.[1]
    return obj
  }

  const init = () => {
    const el = document.querySelector(".contact");
    el.onclick = () => {
      window.location.href = "mailto:vivalab@163.com";
    };

    const ios = document.querySelectorAll(".ios-download");
    const android = document.querySelectorAll(".android-download");
    const pop = document.querySelector(".pop");
    const fn = () => {
      pop.className = "pop current-block";
      setTimeout(() => {
        pop.className = "pop current-none";
      }, 1000);
    };
    ios[0].onclick = () => i18n.language === 'cn' ? fn() : window.location.href = 'https://apps.apple.com/us/app/facefly-face-swap-editor/id1621053689';
    ios[1].onclick = () => i18n.language === 'cn' ? fn() : window.location.href = 'https://apps.apple.com/us/app/facefly-face-swap-editor/id1621053689';
    android[0].onclick = () => i18n.language === 'cn' ? fn() : window.location.href = 'https://play.google.com/store/apps/details?id=com.minilab.facefly';
    android[1].onclick = () => i18n.language === 'cn' ? fn() : window.location.href = 'https://play.google.com/store/apps/details?id=com.minilab.facefly';

    const footerInfo = document.querySelector(".footerInfo");
    const police = document.querySelector('.police')
    if (window.location.host === "www.faceflyapp.com") {
      footerInfo.innerText =
        "Copyright © Faceleap 2021.All Rights Reserved 浙ICP备2022009499号-1";
      police.innerText = '浙公安网备 33010602012411'
      return
    }
    if (window.location.host === "www.faceflyint.com") {
      footerInfo.innerText =
        "Copyright © Faceleap 2021.All Rights Reserved 浙ICP备2022009499号-2";
      police.innerText = '浙公安网备 33010602012414'
      return
    }
    footerInfo.innerText =
      "Copyright © Faceleap 2021.All Rights Reserved 浙ICP备2022009499号-1";
    police.innerText = '浙公安网备 33010602012411'
  }

  useEffect(() => {
    console.log(getLang().lang, '123');

    getLang().lang === 'cn' ? i18n.changeLanguage('cn') : i18n.changeLanguage('en')
    init()
    console.log(i18n, 'i18n');
  }, [window.location.href])

  return (
    <div className="container">
      <div className="box1">
        <img src={require("./assets/logo_facefly.png")} alt className="logo" />
        <div className="name">{t('facefly')}</div>
        <div className="title">{t('text')}</div>
        <div className="down-box">
          <a href="javascript:;" className="ios-download">
            <img src={i18n.language === 'cn' ? require("./assets/APPLE.png") : require('./assets/APPLE_en.png')} alt className="app-store" />
          </a>
          <a href="javascript:;" className="android-download">
            <img src={i18n.language === 'cn' ? require('./assets/Android.png') : require('./assets/Google_en.png')} alt className="app-store" />
          </a>
        </div>
        <i className="mouse-icon">
          <img
            src="https://tempo-rc.vivadyw.com/web/vivaVideo/VISO/img/mouse_icon.png"
            alt=""
          />
        </i>
      </div>
      <div className="box2">
        <img src={i18n.language === 'cn' ? require("./assets/picture_1.png") : require("./assets/picture_1_en.png")} alt className="main" />
        <img src={i18n.language === 'cn' ? require("./assets/picture_2.png") : require("./assets/picture_2_en.png")} alt className="main" />
        <img src={i18n.language === 'cn' ? require("./assets/picture_3.png") : require("./assets/picture_3_en.png")} alt className="main" />
      </div>
      <div className="box-mobile">
        <img src={i18n.language === 'cn' ? require("./assets/picture_1.png") : require("./assets/picture_1_en.png")} alt className="main" />
        <img src={i18n.language === 'cn' ? require("./assets/picture_2.png") : require("./assets/picture_2_en.png")} alt className="main" />
        <img src={i18n.language === 'cn' ? require("./assets/picture_3.png") : require("./assets/picture_3_en.png")} alt className="main" />
      </div>
      <div className="download-btn">
        <a href="javascript:;" className="btn btn-android android-download">
          <img
            src={"https://tempo-rc.vivadyw.com/web/videotempo/static/images/icon-android.png"}
            alt="android"
            className="icon-store"
          />
          <div>Android {t('download')}</div>
        </a>
        <a href="javascript:;" className="btn btn-apple ios-download">
          <img
            src="https://tempo-rc.vivadyw.com/web/videotempo/static/images/icon-apple.png"
            alt="apple"
            className="icon-store"
          />
          <div>Appstore {t('download')}</div>
        </a>
      </div>
      <div className="pop">{t('jqqd')}</div>
      <footer>
        <p>
          <a href={
            i18n.language === 'cn'
              ? "https://rc-chn.faceflyint.com/web/h5template/85f5c906-8bd7-4faf-9c99-a9790e9c2822-language=zh-CN/dist/index.html"
              : "https://rc-sgp.faceflyext.com/web/h5template/2f71be95-ffc2-4ee1-9c7b-9e57ea539ff6-language=en/dist/index.html"
          }>
            <span>{t('yhxy')}</span>
          </a>
          <a href={
            i18n.language === 'cn'
              ? "https://rc-chn.faceflyint.com/web/h5template/3ce8e815-ebd0-4083-bc18-b4ff985dbc04-language=zh-CN/dist/index.html"
              : "https://rc-sgp.faceflyext.com/web/h5template/7c44aa2e-15ad-48c4-9542-3ccaba6cf08d-language=en/dist/index.html"
          }
            className="about-us"
          >
            <span>
              {t('ystk')}
            </span>
          </a>
          <a href="javascript:;" className="about-us">
            <span>
              {t('gywm')}
              <span className="about-us-text about-us-pop">
                {t('gywmms')}
                <i></i>
              </span>
            </span>
          </a>
          <a href="javascript:;" className="about-us">
            <span>
              {t('lxwm')}
              <span className="about-us-text">
                {t('lxwmms')}
                <span href="mailto:faceflyapp@gmail.com" className="contact">
                  faceflyapp@gmail.com
                </span>
                <i></i>
              </span>
            </span>
          </a>
        </p>
      </footer>

      <p className="copyright">
        <a
          style={{ color: '#808080' }}
          target="_blank"
          className="footerInfo"
          href="http://beian.miit.gov.cn/"
        >
        </a>
        <img src={require('./assets/ga.png')} ></img>
        <a
          target="_blank"
          className='police'
          href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${window.location.host === "www.faceflyint.com" ? '33010602012414' : '33010602012411'}`}
        >
        </a>
      </p>
    </div>
  )
}

export default App;
